import * as React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../../themes';
import treatmentIcons from '../../../components/icons/treatmentIcons';
import GeneralDefinitions from '../../../config/generalDefinitions';

import TreatmentsLayout, { createDiseaseRows } from '../../../components/treatmentsLayout';

const Content = styledComponents.div`
  margin: -40px auto 40px;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 80px 0px 0px;
  opacity: 1;

  padding-bottom: 30px;

  @media screen and (max-width: 1290px){
    max-width: 90%;
  }

  @media screen and (max-width: 1024px){
    padding-bottom: 40px;
  }

  @media screen and (max-width: 699px){
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
`;

const OverviewContainer = styledComponents.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;

  & > * {
    margin: 20px 30px;
    padding-top: 0;
  }

  @media screen and (max-width: 699px){
    flex-wrap: wrap;
  }
`;

const OverviewText = styledComponents.div`
  font: normal normal normal 22px/32px PT Sans;

  .lead {
    font: normal normal bold 22px/32px PT Sans;
    color: ${THEME.COLORS.PRIMARY};
    text-transform: uppercase;
  }

  @media screen and (max-width: 699px){
    margin: 20px 8px;
  }

`;

const TreatmentIcon = styledComponents.img`
  width: 153px;
  background-color: ${THEME.COLORS.WHITE};
  padding: 0;
  border-radius: 60px;
  margin-top: 50px;
`;

const EsteticalDematology = () => (
  <TreatmentsLayout title='Dermatologia Estética' pageName='tratamentosCapilares'>
    <Content>
      <OverviewContainer>
        <TreatmentIcon src={treatmentIcons.iconCapilar} />
        <OverviewText>
          <p className='lead'>
            TRICOLOGIA {'&'} TRATAMENTOS CAPILARES
          </p>
          <p>
            A <strong>TRICOLOGIA </strong> é a área da Dermatologia responsável por estudar
            e cuidar da saúde dos cabelos, pêlos e do couro cabeludo.
            <br /><br />
            Desde os primórdios da humanidade, o cabelo sempre fora exaltado sob diversas
            maneiras, tanto em inúmeros momentos de nossa História, em peças arqueológicas,
            pinturas e esculturas, mitologia, passando por várias importantes etapas de
            nossa evolução. Era também mencionado na antiguidade como símbolo de força,
            sensualidade e até mesmo de poder.
            <br /><br />
            Além de ser uma expressão individual de beleza, estilo e identidade de todos
            nós, o cabelo também é importante sinônimo de saúde.
            <br /><br />
            Quando apresentamos qualquer descompasso – seja este por fatores causais externos
            ou internos – é um dos primeiros anexos cutâneos a nos emitir um “sinal de alerta”,
            indicando que alguma possível disfunção em nossa saúde merece um cuidado especial.
            <br /><br />
            Sendo assim, é muito importante estarmos atentos a qualquer mudança nas madeixas
            e também no couro cabeludo, como: aumento da quantidade de fios em queda,
            alterações no volume, textura ou rarefações, presença de prurido (coceira),
            feridas ou crostas, ou quaisquer outros sinais e sintomas que representem algo
            “novo” na rotina do paciente.
            <br /><br />
            Devemos lembrar que muitas fontes contribuem para a sensibilização, desde causas
            genéticas, fatores ambientais, medicamentos, grandes cirurgias, estresse, efeitos
            colaterais de tratamentos quimioterápicos, processos químicos ou determinadas doenças.
            <br /><br />
            Por este motivo, faz-se necessária a avaliação do Médico Dermatologista, onde através
            da história pessoal do paciente, exame clínico dermatológico (em alguns casos, pode
            ser necessário a complementação com exames laboratoriais), as possíveis causas serão
            investigadas. A partir daí, serão elaborados os tratamentos adequados para cada caso.
            <br /><br />
            Os métodos terapêuticos indicados podem ser clínicos e/ou sob a forma de tratamentos
            capilares adjuvantes.
            <br /><br />
            Possuímos em nossos consultórios tratamentos de alta tecnologia e procedimentos capazes
            de prevenir, tratar e manter a saúde capilar com a máxima eficiência.
            Conheça-os a seguir:
          </p>
        </OverviewText>
      </OverviewContainer>
      {createDiseaseRows(GeneralDefinitions.capilarDiseases)}
    </Content>
  </TreatmentsLayout>
);

export default EsteticalDematology;
